import React from "react"
import { Link, graphql } from "gatsby"
import Image from "gatsby-image"
import SEO from "../components/SEO"
import Bio from "../components/Bio"

import styled from "styled-components"

export default function HomePage({ data, location }) {
  const ListStyle = styled.div`
    display: flex;
    flex-direction: row;
    /* --rows: 2; */
    /* grid-template-rows: repeat(var(--rows), subgrid); */
    h2 {
      font-size: var(--extra-heading);
    }
    p {
      font-size: var(--heading-1);
      margin-top: 1rem;
    }
    ol {
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      /* gap: 1rem; */
      margin-right: 2rem;
      /* flex-direction: row; */
      justify-content: space-between;
    }
    li {
      flex: 0 49%;
      img {
        align-self: center;
      }
    }
    @media (max-width: 800px) {
      h2 {
        font-size: var(--heading-2);
      }
      p,
      small {
        font-size: var(--heading-3);
        margin-top: 0;
      }
    }
    @media (max-width: 550px) {
      li {
        flex: 0 99%;
      }
    }
  `
  console.log(data)
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes
  if (posts.length === 0) {
    return (
      <>
        <SEO title="All posts" />
        <Bio />
        <p>
          No blog posts found. Add markdown posts to "content/blog" (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js).
        </p>
      </>
    )
  }

  return (
    <>
      <SEO title="All posts" />
      <h1 className="center">
        <Bio />
      </h1>
      <main role="main">
        <ListStyle>
          <ol>
            {posts.map(post => {
              const title = post.frontmatter.title || post.fields.slug
              return (
                <li key={post.fields.slug}>
                  <article
                    className="post-list-item"
                    itemScope
                    itemType="http://schema.org/Article"
                  >
                    <header>
                      <h2>
                        <Link to={post.fields.slug} itemProp="url">
                          <span itemProp="headline">{title}</span>
                        </Link>
                      </h2>
                      <small>{post.frontmatter.date}</small>
                    </header>
                    <Image
                      fixed={
                        post.frontmatter.featuredImage.childImageSharp.fixed
                      }
                      alt={title || ``}
                    />
                    <section>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: post.excerpt || post.frontmatter.description,
                        }}
                        itemProp="description"
                      />
                    </section>
                  </article>
                </li>
              )
            })}
          </ol>
        </ListStyle>
      </main>
    </>
  )
}

// export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { templateKey: { eq: "blog" } } }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          templateKey
          featuredImage {
            id
            childImageSharp {
              fixed(width: 50, height: 50, quality: 95) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  }
`
